import styled from 'styled-components'
import { xl, lg, md, metaFont } from 'config/variables'

export const SitescreensecStyles = styled.div`
  .content-container {
    max-width: 867px;
    margin: 0 auto;
    margin-bottom: 72px;
    ${xl(`
      margin-bottom: 70px;
  `)}
    ${md(`
      margin-bottom: 40px;
  `)} 
    .SubHead {
      font-family: ${metaFont};
      margin: 30px 0;
      ${md(`
        margin:20px 0;
      `)}
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    .img-blk {
      flex: 0 1 590px;
      &:first-child {
        margin-left: -20px;
      }
      &:last-child {
        margin-right: -20px;
        padding-top: 220px;
        ${lg(`
          padding-top: 150px;
      `)}
        ${md(`
          padding-top: 60px;
      `)}
      }
    }
  }
`
