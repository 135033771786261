import styled from 'styled-components'
import { bodyFont, xl, lg, md, sm, sh } from 'config/variables'

export const ArchitecturesecStyles = styled.div`
  .architech-row {
    display: flex;
    justify-content: space-between;
    ${xl(`
        margin:0 -20px;
    `)} 
    ${lg(`
      flex-direction: column;

    `)} 
    .left-part {
      flex: 0 1 400px;
      ${xl(`
        flex: 1 1 auto;
        padding:0 20px;
      `)} 
      ${lg(`
        padding-bottom:40px;
      `)} 
      ${md(`
        padding-bottom:10px;
      `)} 
    }
    .right-part {
      flex: 0 1 680px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      ${xl(`
        flex: 0 0 640px;
     `)} 
     ${lg(`
        margin:0 -20px;
     `)} 
      ${md(`
        margin:0;
     `)} 
      .single-service-blk {
        flex: 0 1 310px;
        margin-bottom: 46px;
        ${xl(`
          flex: 0 1 50%;   
          padding:0 20px;
        `)} 
        ${lg(`
          flex: 0 1 50%;   
          padding:0 20px;
        `)} 
         ${md(`
          flex: 1 1 100%;   
          padding:0 0;
          margin-bottom: 30px;
        `)} 
        &:last-child {
          ${md(`
            margin-bottom: 0px;
          `)} 
        }
        .icon {
          margin-bottom: 26px;
          ${md(`
              margin-bottom: 16px;
            `)} 
        }
        h4 {
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.4px;
          font-family: ${bodyFont};
          font-weight: 500;
          margin-bottom: 20px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .client-row {
    margin-top: 126px;
    ${xl(`
      margin-top: 100px;
    `)} 
    ${lg(`
      margin-top: 90px;
    `)} 
    ${md(`
      margin-top: 40px;
    `)} 
    .client-row-wrap {
      display: flex;
      justify-content: space-between;
      ${xl(`
          margin:0 -20px;
      `)}
      ${lg(`
          margin:0 -15px;
      `)}
      ${md(`
        flex-direction: column;
      `)} 
      .left-part {
        flex: 0 1 514px;
        ${sh(`
          flex: 0 0 45%;
        `)} 
        ${xl(`
          flex: 0 1 440px;
          padding:0 20px;
        `)} 
         ${lg(`
          flex: 0 0 330px;
          padding:0 15px;
        `)} 
        figure {
          margin-left: -16px;
          width: calc(100% + 32px);
          ${lg(`
            margin-left: 0;
            width: 100%;
          `)} 
          ${md(`
              margin: 30px 0;
          `)} 
        }
      }
      .right-part {
        flex: 0 1 540px;
        ${sh(`
          flex: 0 0 50%;
        `)}
        ${xl(`
          flex: 0 1 504px;
          padding:0 20px;
        `)} 
         ${lg(`
          flex: 1 1 auto;
          padding:0 15px;
        `)} 
        ul {
          padding-left: 22px;
          margin-bottom: 40px;
          ${xl(`
             margin-bottom: 30px;
            `)}  
          ${lg(`
             margin-bottom: 25px;
            `)} 
          ${md(`
             margin-bottom: 20px;
            `)} 
          li {
            font-size: 22px;
            line-height: 32px;
            letter-spacing: -0.15px;
            ${md(`
              font-size: 20px;
                line-height: 30px;
            `)} 
            ${sm(`
             font-size: 18px;
              line-height: 28px;
            `)} 
          }
        }
      }
    }
  }
`
