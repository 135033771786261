import React from 'react'
import { SitescreensecStyles } from './Sitescreensec.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Sitescreensec(props) {
  const { SitescreensecProps } = props.sitescreendata
  const caseQuoteProp = SitescreensecProps.caseQuoteProps
  const {
    data: {
      sprint: {
        childImageSharp: { fluid: sprint },
      },
      screen1: {
        childImageSharp: { fluid: screen1 },
      },
      screen2: {
        childImageSharp: { fluid: screen2 },
      },
      screen3: {
        childImageSharp: { fluid: screen3 },
      },
      screen4: {
        childImageSharp: { fluid: screen4 },
      },
      screen5: {
        childImageSharp: { fluid: screen5 },
      },
      screen6: {
        childImageSharp: { fluid: screen6 },
      },
      screen7: {
        childImageSharp: { fluid: screen7 },
      },
      screen8: {
        childImageSharp: { fluid: screen8 },
      },
    },
  } = props
  let itemLeft = [screen1, screen2, screen3, screen4]
  let itemRight = [screen5, screen6, screen7, screen8]
  return (
    <SitescreensecStyles>
      <div className="container">
        <div className="content-container">
          <h2>{SitescreensecProps.heading}</h2>
          <p>{SitescreensecProps.toppara}</p>
          <h3 className="SubHead">{SitescreensecProps.subHeading}</h3>
          <p>{SitescreensecProps.subpara}</p>
          <figure>
            <Image
              fluid={sprint}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 259,
              }}
            />
          </figure>
          <p>{SitescreensecProps.bottomPara}</p>
        </div>
        <div className="row">
          <div className="img-blk">
            {itemLeft.map((itemLeftprop, i) => {
              return (
                <Image
                  key={i}
                  fluid={itemLeft[i]}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 696,
                  }}
                />
              )
            })}
          </div>
          <div className="img-blk">
            {itemRight.map((itemRightprop, i) => {
              return (
                <Image
                  key={i}
                  fluid={itemRight[i]}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 914,
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </SitescreensecStyles>
  )
}

export default Sitescreensec
