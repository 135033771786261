import React, { useRef, useEffect } from 'react'
import { MigratingcomponentStyles } from './Migratingcomponent.styles'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import { imgSlider } from 'components/before-after-imgSlider'
import { images } from 'config/images'

function Migratingcomponent(props) {
  const { MigratingcomponentProps } = props.migratingcomdata
  const caseQuoteProp = MigratingcomponentProps.caseQuoteProps

  const wrapperRef = useRef()
  useEffect(() => {
    if (wrapperRef.current) {
      imgSlider()
    }
  }, [])
  return (
    <MigratingcomponentStyles>
      <div className="container">
        <h2>{MigratingcomponentProps.heading}</h2>
        <div className="small-container">
          <div className="content-part">
            <p>{MigratingcomponentProps.topPara}</p>
            <div className="img-frame">
              <img src={images.iPadPro} alt="" />
              <div ref={wrapperRef} className="wrapper-img">
                <div className="before">
                  <img src={images.fihNew} alt="" />
                </div>
                <div className="after">
                  <img src={images.fihOld} alt="" />
                </div>
                <div className="scroller"></div>
              </div>
            </div>
            <p>{MigratingcomponentProps.bottomPara}</p>
          </div>
        </div>
        <h2>{MigratingcomponentProps.backHeading}</h2>
        <div className="backend-services">
          {MigratingcomponentProps.backServicelists.map(
            (backServicelist, i) => {
              return (
                <div key={i} className="single-service">
                  <div className="img-blk-wrap">
                    <div className="img-blk">
                      <img src={backServicelist.icon} alt="" />
                    </div>
                  </div>
                  <h4>{backServicelist.title}</h4>
                  <p>{backServicelist.content}</p>
                </div>
              )
            }
          )}
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </MigratingcomponentStyles>
  )
}

export default Migratingcomponent
