import React from 'react'
import { FihscreensStyles } from './Fihscreens.styles'
import Image from 'components/ImgOptimized'

function Fihscreens(props) {
  const {
    data: {
      fihscreen1: {
        childImageSharp: { fluid: fihscreen1 },
      },
      fihscreen2: {
        childImageSharp: { fluid: fihscreen2 },
      },
      fihscreen3: {
        childImageSharp: { fluid: fihscreen3 },
      },
    },
  } = props
  let items = [fihscreen1, fihscreen2, fihscreen3]
  return (
    <FihscreensStyles>
      <div className="container">
        {items.map((item, i) => {
          return (
            <div key={i} className="img-blk">
              <Image
                fluid={items[i]}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 593,
                }}
              />
            </div>
          )
        })}
      </div>
    </FihscreensStyles>
  )
}

export default Fihscreens
