import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Casebanner from 'components/Casebanner/Casebanner'
import { FIHProps } from 'components/Props/fih'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Challangesgoalsec from 'components/Challangesgoalsec/Challangesgoalsec'
import Resulttwocol from 'components/Resulttwocol/Resulttwocol'
import Sitescreensec from 'components/Sitescreensec/Sitescreensec'
import Architecturesec from 'components/Architecturesec/Architecturesec'
import Migratingcomponent from 'components/Migratingcomponent/Migratingcomponent'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Fihscreens from 'components/Fihscreens/Fihscreens'
import Keybenefitssec from 'components/Keybenefitssec/Keybenefitssec'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const FIHPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #d9d9d9;
          }
        }
      }
    }
  }
`

const FIH = props => {
  const { data } = props
  const { location } = props
  const item = props.data.techArchitechDia.childImageSharp.fluid
  const itembottom = props.data.utilizingDiagram.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="FIH"
        description="Simform and FIH worked together to craft a digital web experience with modern UX, scalable architecture and GDPR compliancy within the tight timeframe of 3 months."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/fih/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/fih/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <FIHPage>
        <Casebanner bannerdata={FIHProps} {...props} showFullimages={true} />
        <Caseintroduction
          caseintrodata={FIHProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Challangesgoalsec challangesgoaldata={FIHProps} {...props} />
        </div>
        {showContent && (
          <>
            <Engagementsec
              engagementsecdata={FIHProps}
              {...props}
              showFullimages={true}
            />
            <Resulttwocol resulttwocoldata={FIHProps} {...props} />
            <Sitescreensec sitescreendata={FIHProps} {...props} />
            <Casecontentfullwimg
              casecontentfullwdata={FIHProps.casecontentfullwProps}
              fluid={item}
            />
            <Fihscreens {...props} />
            <Architecturesec architecturesecdata={FIHProps} {...props} />
            <Migratingcomponent migratingcomdata={FIHProps} {...props} />
            <Casecontentfullwimg
              casecontentfullwdata={FIHProps.casecontentfullwbottomProps}
              fluid={itembottom}
            />
            <Keybenefitssec KeyBenefitsdata={FIHProps} {...props} />
            <Relatedcaselist relatedcaselistdata={FIHProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={FIHProps} />
          </>
        )}
      </FIHPage>
    </Layout>
  )
}

export default FIH

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/fih-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    sachinImage: file(relativePath: { regex: "/sachinImg@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    overviewBanner: file(relativePath: { regex: "/overview-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 633) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    sprint: file(relativePath: { regex: "/sprint@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen1: file(relativePath: { regex: "/dscreen-1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen2: file(relativePath: { regex: "/dscreen-2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen3: file(relativePath: { regex: "/dscreen-3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen4: file(relativePath: { regex: "/dscreen-4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen5: file(relativePath: { regex: "/dscreen-5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen6: file(relativePath: { regex: "/dscreen-6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen7: file(relativePath: { regex: "/dscreen-7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    screen8: file(relativePath: { regex: "/dscreen-8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jmeterse: file(relativePath: { regex: "/jmeterse@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    decouplingMobile: file(
      relativePath: { regex: "/decoupling-mobile@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1139) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    bespokeImage: file(relativePath: { regex: "/bespoke-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1139) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(relativePath: { regex: "/fihmainscreen@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1166) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(relativePath: { regex: "/headless-cms@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1116) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    utilizingDiagram: file(
      relativePath: { regex: "/utilizing-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1116) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihscreen1: file(relativePath: { regex: "/fihscreen1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1127) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihscreen2: file(relativePath: { regex: "/fihscreen2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1126) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihscreen3: file(relativePath: { regex: "/fihscreen3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1170) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    KeybenefitsThumb: file(relativePath: { regex: "/benefit-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 242) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/swiftshopper-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/redbull-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
