import styled from 'styled-components'
import { xl, lg, md, sm } from 'config/variables'

export const MigratingcomponentStyles = styled.div`
  .small-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 120px;
    ${xl(`
         margin-bottom: 100px;
    `)} 
    ${lg(`
         margin-bottom: 70px;
    `)} 
    ${lg(`
         margin-bottom: 40px;
    `)} 
    .content-part {
      flex: 0 1 867px;
      ${lg(`
         flex: 1 1 auto;
      `)}
      .img-frame{
        width: 100%;
        margin: 56px 0 37px;
        position: relative;
        user-select: none;
        ${lg(`
            margin: 50px 0;
        `)} 
         ${md(`
            margin: 30px 0;
        `)} 
        .wrapper-img{
          width: calc(100% - 108px);
          height: calc(100% - 104px);
          position: absolute;
          top: 33px;
          left: 38px;
          ${md(`
            width: calc(100% - 70px);
            height: calc(100% - 67px);
            top: 24px;
            left: 26px;
          `)} 
          ${sm(`
            width: calc(100% - 40px);
            height: calc(100% - 45px);
            top: 15px;
            left: 15px;
          `)} 
          .after, .before{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            overflow: hidden;
            user-select: none;
            img{
              height: 100%;
              max-width: min-content;
              pointer-events: none;
            }
          }
          .scroller{
            background: #35C13C;
            width: 10px;
            height: calc(100% + 80px);
            border-radius: 5px;
            position: absolute;
            top: -35px;
            cursor: pointer;
            pointer-events: auto;
            ${md(`
              top: -30px;
              height: calc(100% + 60px);
            `)} 
          }
        }
      }
    }
  }
  .backend-services {
    margin-top: 88px;
    display: flex;
    justify-content: space-between;
    ${xl(`
      margin:60px -20px 0;
    `)} 
    ${lg(`
      margin:60px -20px 0;
    `)} 
    ${md(`
      margin:40px 0 0;
      flex-direction: column;
    `)} 
    .single-service {
      flex: 0 1 540px;
      ${xl(`
           flex: 0 1 50%;
           padding:0 20px;
      `)} 
       ${lg(`
           flex: 0 1 50%;
           padding:0 20px;
      `)} 
       ${md(`
           flex: 0 1 100%;
           padding:0 0px;
           margin-bottom:40px;
      `)} 
      &:last-child {
        ${md(`
           margin-bottom:0;
      `)} 
      }
      .img-blk-wrap {
        margin-bottom: 50px;
        ${md(`
           margin-bottom: 50px;
        `)} 
        .img-blk {
          margin: 20px;
          position: relative;
          background: #ffdcf9;
          width: 254px;
          height: 254px;
          display: flex;
          align-items: center;
          justify-content: center;
          ${md(`
              width: 220px;
              height: 220px;
              padding:40px;
          `)} 
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -20px;
            right: -20px;
            z-index: -1;
            background: #ffdcf9;
            opacity: 0.3;
          }
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: -20px;
            left: -20px;
            z-index: -1;
            background: #ffdcf9;
            opacity: 0.2;
          }
        }
      }
      h4 {
        font-size: 29px;
        line-height: 48px;
        letter-spacing: -0.32px;
        margin-bottom: 9px;
        ${md(`
             font-size: 21px;
             line-height: 28px;
          `)} 
      }
    }
  }
`
