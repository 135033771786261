import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'

export const FihscreensStyles = styled.div`
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 138px;
  ${xl(`
      padding-bottom: 40px;
      margin-bottom: 100px;
  `)} 
  ${lg(`
      margin-bottom: 90px;
      padding-bottom: 30px;
  `)} 

  ${md(`
      margin-bottom: 40px;
      padding-bottom: 30px;
  `)} 
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 800px;
    background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f7f7f7);
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .img-blk {
    margin-bottom: 40px;
    ${xl(`
      margin-bottom: 30px;
  `)} 
    ${lg(`
        margin-bottom: 20px;
    `)} 
    ${md(`
        margin-bottom: 10px;
    `)} 
  }
`
