import React from 'react'
import { images } from 'config/images'

export const FIHProps = {
  bannerProps: {
    heading: 'FIH',
    bannerPara:
      'Improving the online web experience for International Hockey Federation (FIH)',
    BannerAlt: 'International Hockey Federation Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        The Fédération Internationale de Hockey, known as FIH, is the
        international governing body of field hockey comprising of 137 nation
        members.
        <br />
        <br />
        Hockey World Cup 2018 was the biggest event in the organization’s
        history with over 3 million viewers in Asia alone. They wanted to launch
        a more powerful web presence before the event to be the digital
        companion of viewing experience.
      </>
    ),
  },
  challangesgoalProps: {
    heading: 'Challenges and goals',
    leftPartext:
      'We had to deliver fully tested QUALITY website in a tight timeframe of 3 months before the World Cup started on November 26th.',
    rightPara: (
      <>
        FIH was clear in their vision and expectations from the new website. To
        make the website digital companion for all things Hockey, they wanted
        increased user engagement, usability, discoverability, while providing
        modern user experience (UX).
        <br />
        <br />
        The architecture of the website needed to be scalable, cut costs, and be
        flexible while following GDPR guidelines. We helped set up cloud
        migration and DevOps CI CD pipeline to improve deployment times.
      </>
    ),
    videoURL: images.fihVideo,
    bottomPara:
      'On the front-end side the goal was to create a platform that supports different content types, widgets/components, DYNAMIC content page creation with the ability to iteratively adopt changing requirements without breaking the architecture.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “To have achieved the goal in such a condensed amount of time,
          </span>{' '}
          with so many moving parts is a remarkable case study for anyone who is
          trying to pull something off like this.”
        </>
      ),
      userImg: images.harsimranSingh,
      userImg2x: images.harsimranSingh2x,
      userName: 'Harsimran Singh Virk',
      designation: 'Head of Technology',
    },
  },
  ResulttwocolProps: {
    heading: 'Result',
    para: (
      <>
        We successfully launched the fully tested and performant site before the
        World Cup. FIH was able to gain traffic and increase the number of
        repeat readers through subscriber count. The site was performant to
        handle over thousand visitors during the World Cup final. <br />
        <br />
        The site has garnered rave reviews and as a side effect resulted in word
        of mouth business generation for us. We supported FIH through World Cup
        and are now continually supporting them through other tournaments.
      </>
    ),
    resultServicelists: [
      {
        icon: images.iconSmarter,
        title: 'Smarter Publishing',
        content:
          'Content authors gain more control with the UI/UX decoupled from the CMS. This helps authors control the look and feel of the content without coding work from designers and developers.',
      },
      {
        icon: images.iconScalability,
        title: 'Better Scalability',
        content:
          'The entire CMS application will be hosted on a cloud platform, which scales up or down automatically based on business needs.',
        className: 'pale-orange',
      },
      {
        icon: images.iconDowntime,
        title: 'Zero Downtime',
        content:
          'The entire application build was completely automated with Jenkins. This will ensure no downtime during new deployments.',
        className: 'robin-s-egg',
      },
      {
        icon: images.iconCost,
        title: 'Anticipated Cost Savings',
        bulletList: [
          'No additional costs on licensing costs',
          'Eliminating annual maintenance costs',
        ],
        className: 'carnation',
      },
    ],
  },
  SitescreensecProps: {
    heading: 'Lean UX and Agile Development for faster delivery',
    toppara: (
      <>
        We only had three months to launch a completely revamped web presence.
        Usually in projects like these when the website already has millions of
        visits a month, three months or more are spent on UX and user research
        alone. <br />
        <br />
        We employed Lean UX principles with Agile development to deliver UX
        driven development. We made sure all stakeholders, UX team, and
        technical team were working as one cross-functional team.
      </>
    ),
    subHeading:
      'Our goal was to research and test before, during, and after development.',
    subpara:
      'If we identified friction in a UI, it was resolved before it even went into development. By being able to test throughout the process, we were able to reduce our risk and increase the speed for time-to-market.',
    bottomPara:
      'We used design spikes in-between sprints to provide us the freedom to do the research we need to do for a particular feature set. It gave us the time to think about the big picture. The design spikes consisted of usability testing, user research, and prototype building etc so that every feature and page was designed according to users’ needs and goals.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Simform has made excellent technical choices and produces
            high-quality software...
          </span>{' '}
          When you're trying to build quick, at scale, with a customer-first
          approach, there's probably no better organization to partner with than
          Simform.”
        </>
      ),
      userImg: images.vinaymhetre,
      userImg2x: images.vinaymhetre2x,
      userName: 'Vinay Mhetre',
      designation: 'Technology Manager',
    },
  },
  casecontentfullwProps: {
    heading: 'Headless CMS with performant front-end',
    para: (
      <>
        Our client had built the site on legacy architecture and invested
        heavily in licenses for Enterprise Web Content Management (EWCM)
        platform for managing its business-critical content assets. There was a
        need for improving the current CMS platform in terms of reducing
        dependency on developers and improving application performance. To
        address these challenges, our team of experts had recommended the client
        to migrate it to a headless CMS delivery.
      </>
    ),
    imgCaption:
      'Headless CMS architecture to separate back-end content functions from front-end functions',
    bottomPara:
      'This approach helped realize the advantages of decreasing vendor dependency in addition to reducing software license costs. Headless CMS also gives scalability by decoupling CMS and Front-end. We wanted a common language and repository for creating and reusing components. If a new developer joins the team, we wanted to point them at a single page of documentation that explains how to get up and running — and to start building apps the same day. We chose to architect the site with component driven React front-end. ',
  },
  ArchitecturesecProps: {
    architechHeading: 'Our new architecture has the following characteristics',
    architechPara: (
      <>
        We separated the core application infrastructure and UI from data by
        using App shell architecture. All of the UI and infrastructure is cached
        locally using a service worker so that on subsequent loads, retrieve the
        necessary data, instead of having to load everything.
        <br />
        <br />
        We chose the caching strategy so that we show time-sensitive data like
        scores, match results, latest news, as fresh as possible, while match
        images or article content were updated less frequently.
      </>
    ),
    serviceLists: [
      {
        icon: images.iconServerside,
        title: (
          <>
            Server-side <br />
            rendering
          </>
        ),
        content:
          'We rendered the entire HTML server-side in React. We filled it out with as much data as possible (such as the user’s email address) so the user sees a complete UI even before Javascript loads.',
      },
      {
        icon: images.iconLoad,
        title: 'Load libraries separately from application source',
        content:
          'With our new custom architecture, we were able to load all our libraries from popular CDNs where it’s highly likely that the user already has them cached. Additionally, when we push a new version of our app, the users don’t need to re-download these libraries.',
      },
      {
        icon: images.iconLazilyload,
        title: 'Lazily load anything and everything we can',
        content:
          'As we found out in our analysis, some libraries are very expensive to load. Fortunately, they aren’t required for first render to show anything meaningful to the user. So we are loading them using async script tags at the very end of the page.',
      },
      {
        icon: images.iconResponsive,
        title: 'Responsive progressive web-app like site',
        content:
          'Mobile is the biggest source of traffic for FIH. We aimed to create a mobile web experience that matches native app experience. We decided to go in the direction of responsive progressive web-app by following Google’s guidelines.',
      },
    ],
    imgCaption: 'Decoupling mobile and web UI from APIs',
    clientHeading: 'Testing client side',
    clientLeftPara: (
      <>
        At Simform, we care about testing! It contributes to reliable,
        maintainable code, and makes software easier to maintain in the long run
        which reduced the overall ownership costs.
        <br />
        <br />
        Most of our competitors focus on testing their servers and APIs. But
        server-side testing is not enough. Since websites have Javascript/Ajax
        enabled, measuring performance from server/API level is not enough. Even
        poorly written javascript code can majorly affect the performance of the
        app.
      </>
    ),
    clientRightTopPara:
      "First let's consider the metrics to measure. Few of the important metrics that should always be considered are:",
    clientBulletList: [
      'Response time which could include Javascript file load time, Image load time, CSS file load time, Content Download time etc.',
      'Number of HTTP Request and HTTP Response status.',
    ],
    clientRightBottomPara: (
      <>
        We used JMeter WebDriver Sampler with Selenium to test client side
        performance. We create custom scripts for different use cases and create
        a threshold for various metrics. Jenkins was used to integrate tests to
        run tests with each new build.
        <br />
        <br />
        As a result - moving to the new architecture was a huge improvement. Our
        time to first render dropped considerably, from almost 8sec (90th
        percentile) down to about one second. We also already received great
        feedback from our users.
      </>
    ),
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “The feedback on the product has been great. Users can <br />
            quickly get information they need.
          </span>{' '}
          It’s intuitive and easy for <br />
          them to find the right Hockey stats.”
        </>
      ),
      userImg: images.harsimranSingh,
      userImg2x: images.harsimranSingh2x,
      userName: 'Harsimran Singh Virk',
      designation: 'Head of Technology',
    },
  },
  MigratingcomponentProps: {
    heading: 'Successfully migrating the older site',
    topPara:
      'Migrating sites with thousands of pages is always a huge challenge from SEO and marketing angle. We worked with their technical and marketing teams on the site migration plan. This involved doing thorough technical SEO audit and making sure the new site not only stays consistent but improves on the technical SEO.',
    bottomPara: (
      <>
        In the older version of site, FIH team had a tough time managing their
        content library as it gave them limited choice. Our key objective from
        the start was to allow CMS users to utilize the power of content that
        best suits their workflow. We had to design CMS so that content managers
        and site moderators can utilise all the content assets they had by
        creating powerful content widgets with dynamic pages.
        <br />
        <br />
        Component driven architecture from React.js and headless CMS
        architecture made it easier for us to give flexibility to content
        managers and moderators. Usually the UX for these users is neglected but
        we worked closely with them to ensure it came out as they had imagined.
        At the end, we were able to deliver a flexible CMS that was both
        powerful and fit users’ workflow.
      </>
    ),
    backHeading: 'Backend engineering for more powerful website',
    backServicelists: [
      {
        icon: images.iconBapi,
        title: 'API integration',
        content:
          'Sports sites have to integrate with third party platforms and media partners to pull up the latest data and information. We developed widgets for CMS users and integrated APIs like live score, player, team, match information and statistics. Integrating with media partners made pulling up latest images and assets for CMS users really handy.',
      },
      {
        icon: images.iconShield,
        title: 'GDPR and Security',
        content:
          'In the new age of better privacy protection and data threats, we had to make sure the FIH site which had a large audience across the globe followed GDPR compliance. Our Information security team worked closely with them to make sure users’ data was protected.',
      },
    ],
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Auto Scaling gives us an automated way to scale storage and compute
            capacity.
          </span>{' '}
          That helps us because our load varies from tournament to
          tournament...We are also very happy with ‘one-click’ deployment of our
          whole infrastructure saving a lot of time and money.”
        </>
      ),
      userImg: images.vinaymhetre,
      userImg2x: images.vinaymhetre2x,
      userName: 'Vinay Mhetre',
      designation: 'Technology Manager',
    },
  },
  EngagementsecProps: {
    Title: 'Engagement',
    subTitle: (
      <>
        We <br />
        became their extended <br />
        team
      </>
    ),
    para: (
      <>
        Our team worked closely with their tech team on user research to
        understand what users want and need. This research helped us choose the
        right features and in designing a user experience containing these
        features. After the successful launch of the site, we have now become an
        extended team of FIH.
      </>
    ),
  },
  casecontentfullwbottomProps: {
    heading: 'Cloud and DevOps engineering for better and faster performance',
    para: (
      <>
        Simform is an Amazon AWS partner and to handle huge spikes in traffic
        while providing low latency, we decided to use AWS. We use Amazon
        Elastic Compute Cloud (Amazon EC2) instances to run the site, and Amazon
        CloudFront as its content delivery network (CDN), serving content to
        100,000 daily visitors to the site. ElastiCache is used for in-memory
        caching and Elastic Load Balancing provides load balancing.
        <br />
        <br />
        We also used Amazon S3 to store videos and Amazon EC2 to run the system
        used to manage the video content. Amazon Elastic Transcoder converts FIH
        video files from source to different formats for viewing on a range of
        devices.
      </>
    ),
    imgCaption:
      'Utilizing various AWS services to get the maximum performance of the system',
    bottomPara: (
      <>
        We managed the AWS Cloud infrastructure using AWS CloudFormation, using
        it to create and manage a collection of related AWS resources,
        provisioning and updating them in an orderly and predictable way.
        <br />
        <br />
        FIH has experienced cost savings by using the AWS Cloud rather than a
        traditional data center. They have also saved time on infrastructure
        maintenance. “Time is our most valuable resource,” says, CTO. “If we had
        an in-house data center, it would take three to five days to provision a
        new instance, With AWS, it only takes us three to five minutes.”
        <br />
        <br />
        Our DevOps team worked with their tech team to set up CI/CD process for
        faster releases. Set up started in the lower environments, and included
        a CI/CD pipeline, to aid the deployment process. Incorporating
        Continuous Integration using Jenkins framework added value not only to
        the delivery quality but also to the process efficiency.
      </>
    ),
  },
  CasePowerToolsProps: {
    mainTitle: '',
    CasePowerToolsDetails: [
      {
        Title: 'Creating engaging brand experiences that extend to digital',
        para:
          'Red bull smartly uses photo booths at strategic locations at events they host to engage hundreds of thousands of event goers a year. We set out to create a fun Kiosk based solution using iPad that extended these physical experience to digital marketing channels.',
      },
      {
        para:
          'The kiosk iPad app offered various beautifully custom-made filters which arrange the clicked photos in 2*2 collage grid along with the event logo and colorful frame. With easy to use interface and fun filters, visitors preferred capturing group moment from the photo booth instead of using their device to click selfies. Captured photos can be later emailed to the visitors and lucky ones will get it featured on the Red Bull website or relevant social media accounts.',
      },
    ],
  },
  KeyBenefitsprops: {
    title: 'Some of the key benefits were',
    BenefitsList: [
      'Reduced the overall time-to-market',
      'Significantly enhanced the organizational ROI from CI',
      'Automated numerous test scripts',
      'Facilitated Jenkins reports that enable better decision making & planning',
      'All CI/CD and infrastructure provisioning workflows were implemented as-code with a YAML-based configuration, which was stored in Github and versioned.',
    ],
    ButtonName: 'Get your goals done Now',
    ButtonLink: '/contact/',
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Swift Shopper',
        para:
          'Helping retailers modernize shopping experience through faster checkouts & customer analytics',
        className: 'pale-mauve-three',
        link: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        title: 'Redbull Photobooth',
        para: 'Shortcut to get featured on Redbull website',
        className: 'powder-blue',
        link: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
