import React from 'react'
import { ArchitecturesecStyles } from './Architecturesec.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Architecturesec(props) {
  const { ArchitecturesecProps } = props.architecturesecdata
  const caseQuoteProp = ArchitecturesecProps.caseQuoteProps
  const {
    data: {
      jmeterse: {
        childImageSharp: { fluid: jmeterse },
      },
      decouplingMobile: {
        childImageSharp: { fluid: decouplingMobile },
      },
    },
  } = props
  return (
    <ArchitecturesecStyles>
      <div className="container">
        <div className="architech-row">
          <div className="left-part">
            <h2>{ArchitecturesecProps.architechHeading}</h2>
            <p>{ArchitecturesecProps.architechPara}</p>
          </div>
          <div className="right-part">
            {ArchitecturesecProps.serviceLists.map((serviceList, i) => {
              return (
                <div key={i} className="single-service-blk">
                  <div className="icon">
                    <img src={serviceList.icon} alt="" />
                  </div>
                  <h4>{serviceList.title}</h4>
                  <p>{serviceList.content}</p>
                </div>
              )
            })}
          </div>
        </div>
        <figure>
          <Image
            fluid={decouplingMobile}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 577,
            }}
          />
          <div className="img-caption">{ArchitecturesecProps.imgCaption}</div>
        </figure>
        <div className="client-row">
          <h2>{ArchitecturesecProps.clientHeading}</h2>
          <div className="client-row-wrap">
            <div className="left-part">
              <p>{ArchitecturesecProps.clientLeftPara}</p>
              <figure>
                <Image
                  fluid={jmeterse}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 232,
                  }}
                />
              </figure>
            </div>
            <div className="right-part">
              <p>{ArchitecturesecProps.clientRightTopPara}</p>
              <ul>
                {ArchitecturesecProps.clientBulletList.map(
                  (clientBullet, i) => {
                    return <li key={i}>{clientBullet}</li>
                  }
                )}
              </ul>
              <p>{ArchitecturesecProps.clientRightBottomPara}</p>
            </div>
          </div>
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ArchitecturesecStyles>
  )
}

export default Architecturesec
